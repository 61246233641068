@include media-breakpoint-up(md) {
    li.nav-top-level .nav-item:not(.nav-offers) a {
        font-weight: 400!important;
    }
}
header#new-homepage-test-header #main-navigation{
    .telephone {
		&::before {
			background: url('data:image/svg+xml;charset=UTF-8, <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68.9 68.9"><path id="Path_15" d="m66.9,48.7c1.3.4,2.1,1.6,2,3,0,.2,0,.5-.1.7l-3.2,14c-.2,1.6-1.5,2.7-3.1,2.6C28,68.9,0,40.9,0,6.5c-.1-1.6,1-2.9,2.6-3.1L16.6.1c.2,0,.4-.1.7-.1,1.3,0,2.5.8,3,2l6.5,15.1c.2.4.3.8.3,1.2,0,1-.4,1.9-1.2,2.6l-8.1,6.6c4.8,10.6,13.2,19.1,23.8,23.8l6.6-8.1c.6-.8,1.6-1.2,2.6-1.2.4,0,.8,0,1.2.3l15.1,6.5Z"/></svg>');
        }
    }
}