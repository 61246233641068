section.media-content {
	.col-12.col-md-3 img {
		@include media-breakpoint-down(sm) {
			height: 350px;
			object-fit: cover !important;
			object-position: center -88px !important;
		}
	}
	div[class^=block-]{
		.text{
			h4{
				margin-top:0;
				@include media-breakpoint-down(sm) {
					margin-bottom: 1.0rem;               
				}
				@include media-breakpoint-only(md) {
					margin-bottom: .5rem;
				}
			}
			@include media-breakpoint-only(md) {
				padding:1.1rem;
			}
			@include media-breakpoint-down(sm) {
				padding:1.5rem;
			}
		}
	}
	
	.content {
		.block-image-top {
			@include media-breakpoint-up(lg) {
				display: flex;
				& a {
					display: flex;
					picture {
						padding: 0 40px;
						border: 1px solid #DCDCDC;
						img {
							border: 0;
						}
					}
				}
			}
			@media (max-width:991px) { 
				& a {
					picture {
						img {
							border: 1px solid #DCDCDC;
						}
					}
				}
			}
		}
	}
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	section.media-content {
		.latest-offer-img{
			display: none;
		}
		.latest-block-1,.latest-block-2,.latest-block-3,.latest-block-4{
			border: 1px solid #DCDCDC;
			background-repeat: no-repeat;
			@include media-breakpoint-down(sm) {
				background-position: bottom 88px right 50%;
				background-size: contain;
			}
			@include media-breakpoint-up(sm) {
				background-position: bottom 40% right 50%;
				background-size: cover;
			}
			@include media-breakpoint-up(lg){
				background-position: bottom 180px right 50%;
				background-size: contain;
			}
			a:first-child {
				width: 231px;
				height: 240px;
				display: inline-block;
			}
		}
		@include media-breakpoint-down(sm) {
			.block-image-top{
				height: 350px;
			}
		}
	}
}