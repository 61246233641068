/**
 * Header overrides for STWC
 */

// override the logo colour
header#new-homepage-test-header,
header#simple-header {
	
	#search {
		// override for some default styling
		margin-top: auto;
	}

	div.logo {
		@include media-breakpoint-down(sm) {
			a {
				background: url('/images/uk/en/homepage/logos/avy-logo-mobile.svg');
				.avy-logo-img {
                    display: none;
                }
			}
		}

		@include media-breakpoint-up(md) {
			top: 7px;
			a {
				height: 110px;
				svg {
					height: 110px;
					.avy0{fill:#111c24}
					.avy2{fill:#fff;}
    				.avy3{fill-rule:evenodd;clip-rule:evenodd;fill:#003c3f}
				}
			}
		}
	}

	&.is-sticky {
		div.logo {
			@include media-breakpoint-down(sm) {
				a {
					background: url("/images/uk/en/homepage/logos/avy-logo-mobile.svg");
				}
			}
		}
	}

	&.expanded {
		div.logo {
			@include media-breakpoint-down(sm) {
				a {
					background: url("/images/uk/en/homepage/logos/avy-logo-mobile.svg");
				}
			}
		}
	}

	#main-navigation ul li.flipper-container .back-panel .logo {
		background: url('/images/uk/en/homepage/logos/avy-logo-square.svg');
	}
}

header#new-homepage-test-header{
	#cart {
		span.number-of-items{
			background: #CE4801;
		}
	}
	.masthead{
		#top-links{
			#account{
				.js-wr-balance{
					.balance, .dollar{
						color: #003d40;
					}
				}
			}
		}
	}
}

header#simple-header {

	@include media-breakpoint-up(md) {

		height: 130px;

		div.logo, div.logo a svg {
			display: inline-block;
			position: relative;
			top: 7px;
			-ms-transform: translateY(0);
			transform: translateY(0);
		}

		div.logo a svg {
			left: 50%;
			-ms-transform: translateX(-50%);
			transform: translateX(-50%);
		}
	
	}

}

.desktop-search-panel {
	&.focus {
		button[type="submit"] {
			height: 47px;
			@-moz-document url-prefix() { 
					height: 42px;
			}
		}
	}
	button[type="submit"] {
		background: none;
		border: none;
		border-right: 1px solid #a3a3a3;
		svg {
			height: 23px;
			path{
				fill: #474747 !important;
			}
		}
	}
}

