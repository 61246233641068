.hero {
	.tab {	
		.content {
			h3 {
					font-family: 'Libre Caslon Text', sans-serif;
					font-size: 18px;
					margin-bottom: 0;
					font-weight: 600;
				}
		}
	}
}