section.partnership-content {
    h2 {
        @include font-heading(24px);
        font-family: 'Libre Caslon Text', sans-serif;
        text-align: center;
        margin-bottom: 20px;
        @media (max-width:960px) {
            font-size: 22px;
        }
    }
}