footer section.footer-links .nav-item h4 {
    @include font-heading(2em);
    font-weight: 600;
    letter-spacing: -0.75px;
}

footer section.footer-links {
	.contact-details {
		@include media-breakpoint-up(md) {
			border-top: 1px solid #b0b0b0;
			position: inherit;
			top: auto;
			width: 100%;
			margin: 20px 0;
			.submenu {
				li {
					width: 25%;
					float: left;
					padding-top: 20px;
					margin-top: 10px;
					font-size: 1.6rem;

					a {
						margin-top: 10px;
						span {
							font-weight: 600;
						}
					}
				}
			}
		}
	}
	.nav-item{
		i.arrow.up{
			top: 5px;
		}
	}
}

footer {
	section.social-links {
		a {
			line-height: 40px;
			font-weight: bold;
		}
		ul{
			li{
				a{
					svg{
						width: 27px;
						height: 27px;
						path {
							fill: #003d40;
						}
					}
				}
			}
		}
		background: #ffffff;
		.follow{
			color: #000000;
			text-transform: uppercase;
			font-family: "Roboto",sans-serif;
			font-weight: 500;
		}
	}

	section.footer-copyright {
		font-size: 1.1rem;
		.logo {
			margin: 5px 0 0 0;
		}
	}
}

footer section.footer-links .nav-item i.arrow {
	border-color: #303030;
}

footer section.footer-links .nav-item h4 {
	border-color: #303030;
	font-size: 14px;
	font-weight: 400;
	text-transform: uppercase;
	font-family: 'Roboto';
	@include media-breakpoint-down(sm) {
		border: none;
	}
}
@include media-breakpoint-down(sm) {
	footer section.footer-links .group ul.submenu{
		padding: 0 15px;
	}
}