section.recommended {
    background-color: #515556;

    h2 {
        font-size: 24px;
        font-family: 'Libre Caslon Text', sans-serif;
        text-align: center;
        font-weight: 500;
        @media (max-width:960px) {
          font-size: 22px;
        }
    }
}

section.recommended .window li a .image {
    background: radial-gradient(ellipse at center, #cbcccd 0, #cbcccd 100%);
}

section.recommended .scroll {
    border-color: #e5e5e5;
}

section.recommended .view-all {
    .arrow.right {
        right: 300px;
    }
}