@import "~dw-uk-bootstrap/scss/_functions.scss";
@import "~dw-uk-bootstrap/scss/_mixins.scss";
@import "~dw-uk-bootstrap/scss/themes/_averys.scss";

$font-family-base: "open-sans", "Lucida Sans Unicode", "Lucida Grande", sans-serif;
$headings-font-family: "adobe-caslon-pro-n6", "adobe-caslon-pro", "Adobe Caslon Pro", serif;

$header-primary: #155954;
$header-secondary: #742f47;
$light-button-border: 2px solid #6f9b97;
$quicklinks: $header-primary;
$recommended-background: #535456;
$recommended-cta: $header-primary;
$recommended-cta-hover: #07362f;
$footer-background: #e6e6e6;
$footer-color: #303030;
$footer-phone: dark;
$social-links-background: #f8f8f8;
$social-icon-color: #666666;
$legal-background: $header-primary;
$empty-cell-grey: #ccc;

@import "./base.scss";
@import './components/gift-cards/gift-cards.scss';
@import './components/gift-cards/e-vouchers/e-vouchers.scss';
@import './components/**/*.averys.scss';

body.christmas .push-container.header {
	section.footer-links {
		background-image: url('/images/uk/en/law/offers/christmas/Homepage_Christmas_White_Footer-xs.png');
		@include media-breakpoint-up(sm) {
			background-image: url('/images/uk/en/law/offers/christmas/Homepage_Christmas_White_Footer.png');
		}
	}
}
section.giftbanner {
	margin-bottom: 2em;
}
.desktop-search-panel {
	// style the button
	button[type="submit"] {
		background: transparent !important;
		border: 2px solid transparent !important;

		// the icon inside the button
		svg {
			path { fill: #003d40 !important; }
		}
	}
	&.focus {
		// green background for the search
		button[type="submit"] {
			background: transparent !important;
		}
	}
}

.is-sticky {
	div.logo {
		@include media-breakpoint-down(sm) {
			a {
				height: 25px !important;
				width: 100px !important;
			}

			left: 42% !important;
			top: 7px !important;
		}
	}

	.masthead {
		@include media-breakpoint-up(md) {
			#cart {
				.number-of-items {
					background: #ce4801 !important;
				}
			}
		}
	}
}

.masthead {
	svg path,
	svg polygon,
	svg circle {
		// black for mobile
		@include media-breakpoint-down(sm) {
			fill: #000 !important;
		}
	}
}

#cart {
	// mobile
	@include media-breakpoint-down(sm) {
		.number-of-items {
			background: #CE4801 !important;
		}
	}

	// desktop
	@include media-breakpoint-up(md) {
		span.number-of-items {
			background: #ce4801 !important;
		}

		span.total {
			font-weight: bold !important;
			color: #000;
		}
	}
}

#navigation-overlay {
	background: #000 !important;
}

#main-navigation {
	nav {
		// mobile styling
		@include media-breakpoint-down(sm) {
			border-image: linear-gradient(to bottom, #000, #000) 1 !important;

			& > ul {
				border-bottom: 5px solid #000 !important;
			}
		}
	}

	// all UL's
	ul {
		li {
			@include media-breakpoint-up(md) {
				&.nav-top-level {
					&.offers {
						.nav-offers {
							& > a {
								color: #000 !important;
							}
						}
					}
				}
			}

			@include media-breakpoint-down(sm) {
				#mobile-loggedin {
					border-color: #000 !important;
				}

				.nav-item,
				.login-link {
					// drop down arrow, we are using CSS because we can
					i {
						border: solid #003d40 !important;
						border-width: 0 3px 3px 0 !important;
					}
				}

				&.nav-top-level > .nav-item,
				&.nav-login-item > .login-link {
					a {
						color: #000 !important; 
					}
					// SVG icon before the menu
					&:before {
						height: 30px;

						a {
							color: #000 !important; 
						}
					}
					&.nav-home::before {
						background-image: url("/images/uk/en/avy/icons/home.svg") !important;
					}

					&.nav-customer-service::before {
						background-image: url("/images/uk/en/avy/icons/customer-service.svg") !important;
					}

					&.login-link::before,
					&#mobile-loggedin::before {
						background-image: url("/images/uk/en/avy/icons/account.svg") !important;
					}
				}

				// when the item is open, expand the submenu
				&.open-nav {
					& > .nav-item {
						background: #003d40 !important;
						a,
						.rewards-text {
							color: $white !important;
						}
						.dollar {
							color: $white !important;
						}
						.balance {
							color: $white !important;
						}

						i {
							// and flip the arrow around
							transform: rotate(-135deg);
							animation-name: rotateUp;
							animation-duration: 0.2s;
							border-color: $white !important;
						}
					}
				}
			}
		}
	}
}

header#new-homepage-test-header,
header#simple-header  {
	/**
	 * Logo for mobile
	 */
	 div.logo {
		@include media-breakpoint-down(sm) {
			a {
				height: 25px !important;
				width: 100px !important;
			}
			
			left: 42% !important;
			top: 7px !important;
		}
	}
}
