section.newsletter {
    h2 {
        font-size: 24px;
        font-family: 'Libre Caslon Text', sans-serif;
        i {
            font-style: normal !important;
        }
        @media (max-width:960px) {
            font-size: 22px;
        }
    }
}