section.quick-links {
	a.btn {
   		font-family: $font-family-base !important;
	}
    padding: 40px 0;
    text-align: center;
    position: relative;
	border-bottom: 1px solid #ddd;
	border-top:0;
	line-height: 5rem;
	margin: 0 !important;
    @supports (clip-path: polygon(0 0, 100% 0%, 100% 10px, 0 10px)) {
		&::after {
			content: "";
			position: absolute;
			bottom: -11px;
			top:unset;
			left: 0;
			right: 0;
			height: 50px;
			background: linear-gradient(180deg,hsla(0,0%,86.7%,0) 0,#ddd 50%,#ddd);
			border-bottom-left-radius: 100% 100%;
			border-bottom-right-radius: 100% 100%;
			border-top-left-radius: 100% 100%;
			border-top-right-radius: 100% 100%;
			clip-path: polygon(0 0,100% 0,100% 10px,0 10px);
			bottom:-40px;
		}
	}
	@include media-breakpoint-down(md) {
		.row{
			margin: 0 25px;
			@media screen and (min-width: 768px) and (max-width: 1024px) {
				margin: 0 !important;	
			}
		}
	}
}